<template>
	<!--        What the policy advocacy index shows-->
	<Modal
		classes="modal--comparision"
		class="modal--comparision-global"
		@close="handleCloseModal"
	>
		<template #header>
			<VHeading level="2">What would you like to compare?</VHeading>
		</template>
		<template #content>
			<div class="content-left">
				<VText size="small" weight="bold" align="center"
					>Selected SDG top level goal:</VText
				>
				<img
					:src="require(`@/assets/imgs/sdgs/lg/${selectedSdg.imageSlug}.png`)"
					class="content-left__img"
					:alt="selectedSdg.name"
				/>
			</div>
			<div class="content-right">
				<VText size="small" weight="bold" align="center">
                    Selected SDG sub-level goals:
                </VText>
				<div class="content-right__box">
					<div class="form__group">
						<div
							v-for="subgoal in selectedSdg.subgoals"
							class="form__group__inner"
							:key="subgoal.slug"
						>
							<VRadio
								:id="subgoal.slug"
								:label="
									`SDG${selectedSdg.order}.${subgoal.order} - ${subgoal.name}`
								"
								:input-value="subgoal"
								v-model="sdgSublevel"
							/>
						</div>
					</div>
				</div>
				<div class="content-right__box__wrapper">
					<div class="content-right__box__action"></div>
				</div>
				<VButton
					color="primary"
					classes="button__primary--mobile cursor-pointer"
					@click.stop.prevent="setSublevelSdg"
				>
					Compare this sub-level goal
				</VButton>
			</div>
		</template>
	</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { SET_ACTIVE_MODAL, SET_PAD_SDG } from '../store/mutations.type';

import EventBus from '@/eventBus';

export default {
	name: 'SDGModal',
	data() {
		return {
			sdgSublevel: null
		};
	},
	computed: {
		...mapState({
			selectedSdg: state => state.pad.selectedSdg
		})
	},
	created() {
		this.sdgSublevel = this.selectedSdg.subgoals[0];
	},
	methods: {
		setSublevelSdg() {
			EventBus.$emit('SET_SDG', this.selectedSdg, this.sdgSublevel);
			this.closeModal();
		},
		closeModal() {
			this.$store.commit(SET_ACTIVE_MODAL, null);
			this.$store.commit(SET_PAD_SDG, null);
		},
		handleCloseModal(event) {
			event.preventDefault();
			event.stopPropagation();
		}
	}
};
</script>
